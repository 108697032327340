<template>
  <b-overlay :show="show" rounded="sm">
    <div class="row">
      <div class="col-md-3">
        <h5>Start Date</h5>
        <b-form-datepicker
          id="example-datepicker"
          v-model="start_date"
          class="mb-1"
        />
      </div>
      <div class="col-md-3">
        <h5>End Date</h5>
        <b-form-datepicker
          id="example-datepicker2"
          v-model="end_date"
          class="mb-1"
        />
      </div>
      <div class="col-md-1 my-auto">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="search()"
        >
          <feather-icon icon="SearchIcon" size="21" />
        </b-button>
      </div>
    </div>
    <b-row v-if="!show">
      <b-col cols="12">
        <chartjs-line-chart :listAnalytics="listAnalytics" v-if="!show" />
      </b-col>
      <b-col cols="12">
        <echart-bar :listAnalytics="listAnalytics" v-if="!show" />
      </b-col>

      <!-- <b-col cols="12">
        <echart-line :listAnalytics="listAnalytics" v-if="!show" />
      </b-col> -->
      <b-col lg="6">
        <echart-doughnut :listAnalytics="listAnalytics" />
      </b-col>
      <b-col lg="6">
        <chartjs-doughnut-chart :listAnalytics="listAnalytics" />
      </b-col>
      <b-col cols="12">
        <leaflet-marker-circle-polygon :listAnalytics="listAnalytics" />
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import { BRow, BCol, BFormDatepicker, BButton } from "bootstrap-vue";

import EchartLine from "../../charts-and-maps/charts/echart/EchartLine.vue";
import EchartBar from "../../charts-and-maps/charts/echart/EchartBar.vue";
import EchartDoughnut from "../../charts-and-maps/charts/echart/EchartDoughnut.vue";
import ChartjsDoughnutChart from "../../charts-and-maps/charts/chartjs/ChartjsDoughnutChart.vue";
import ChartjsLineChart from "../../charts-and-maps/charts/chartjs/ChartjsLineChart.vue";
import LeafletMarkerCirclePolygon from "../../charts-and-maps/maps/leaflet/LeafletMarkerCirclePolygon.vue";

require("platform");

export default {
  name: "AnalyticsStatistics",
  components: {
    BRow,
    BCol,
    // EchartLine,
    EchartBar,
    EchartDoughnut,
    ChartjsLineChart,
    ChartjsDoughnutChart,
    LeafletMarkerCirclePolygon,
    BFormDatepicker,
    BButton,
  },
  data: () => ({
    listAnalytics: [],
    rootData: [],
    show: true,
    start_date: null,
    end_date: null,
  }),
  mounted() {
    setTimeout(() => {
      this.getListAnalytics();
    }, 500);
  },
  computed: {
    project_uuid() {
      return this.$store.state.Project.project.uuid;
    },
  },
  methods: {
    getListAnalytics() {
      //this.$store.dispatch('Analytics/setNewAnalytics', { session, project_uuid, log_file, IP, operating_system, browser_name, major_version, user_agent }
      if (this.project_uuid) {
        this.$store
          .dispatch("Analytics/getListAnalytics", this.project_uuid)
          .then((res) => {
            res.data.map((e) => {
              // if(i%2==0){
              //   e.IP_detail.country_name = 'USA';
              //   e.IP_detail.city = 'NY';
              // }
              return e;
            });
            if (!this.listAnalytics.length) {
              this.listAnalytics = res.data;
            }
            this.rootData = res.data;
            this.show = false;
          });
      }
    },
    search() { 
      const list = this.rootData.filter((e) => {
        var d1 = new Date(e.create_date).getTime();
        var d2 = new Date(this.end_date).getTime();
        var d3 = new Date(this.start_date).getTime();
         
        return d1 >= d3 && d1 <= d2;
      }); 
      this.listAnalytics = list;
      //this.getDistinctIp();
    },
  },
  watch: {
    project_uuid: function (newVal, oldVal) {
      if (oldVal === undefined) {
        this.getListAnalytics();
      }
    },
  },
};
</script>

<style scoped></style>
