<template>
  <e-charts
    ref="line"
    autoresize
    :options="option"
    theme="theme-color"
    auto-resize
  />
</template>

<script>
import ECharts from "vue-echarts";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/legend";
import "echarts/lib/chart/bar";
import theme from "./theme.json";
import _ from "lodash";

ECharts.registerTheme("theme-color", theme);

export default {
  components: {
    ECharts,
  },
  props: {
    optionData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      option: {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {
          left: 0,
        },
        grid: this.optionData.grid,
        xAxis: this.optionData.xAxis,
        yAxis: this.optionData.yAxis,
        series: this.optionData.series,
      },
    };
  },
  computed: {
    listGroupedByTimezone() {
      let listApi = this.listApi.filter((x) => x.timezone !== undefined);
      return _.chain(listApi)
        .groupBy((x) => x.timezone)
        .value();
    },
    listGroupedByCountry() {
      let listApi = this.listApi.filter((x) => x.country_name !== undefined);
      return _.chain(listApi)
        .groupBy((x) => x.country_name)
        .value();
    },
  },
  methods: {
    setOption() {
      this.option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {
          left: 0,
        },
        grid: this.optionData.grid,
        xAxis: this.optionData.xAxis,
        yAxis: this.optionData.yAxis,
        series: this.optionData.series,
      };
    },
  },
  watch: {
    listAnalytics(val) {
      if (val) {
        val.map((x) => {
          fetch(`https://ipapi.co/${x.IP}/json/`)
            .then(function (response) {
              response.json().then((txt) => {
                x.city = txt.city;
                x.country_name = txt.country_name;
                x.country_code = txt.country_code;
                x.country_code_iso3 = txt.country_code_iso3;
                x.timezone = txt.timezone;
              });
              return x;
            })
            .catch(function (error) {
              console.log(error);
            });
          this.listApi = val;
        });
      }
    },
    optionData: {
      handler(newOption, oldOption) {
        // this._chart.destroy()
        this.setOption( );
      },
      deep: true,
    },
  },
};
</script>
