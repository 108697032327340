<template>
  <b-card no-body>
    <b-card-header>
      <div>
        <b-card-title class="mb-1"> Statistics</b-card-title>
        <b-card-sub-title
          >Commercial number of user per continent</b-card-sub-title
        >
      </div>
    </b-card-header>

    <!-- chart -->
    <b-card-body>
      <chartjs-component-line-chart
        :height="400"
        :data="lineChart.data"
        :options="lineChart.options"
        :plugins="plugins"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BCardSubTitle,
  BCardTitle,
} from "bootstrap-vue"
import ChartjsComponentLineChart from "./charts-components/ChartjsComponentLineChart.vue"
import chartjsData from "./chartjsData"
import _ from "lodash"

import { $themeColors } from "@themeConfig"
// colors
const chartColors = {
  primaryColorShade: "#836AF9",
  yellowColor: "#ffe800",
  successColorShade: "#28dac6",
  warningColorShade: "#ffe802",
  warningLightColor: "#FDAC34",
  infoColorShade: "#299AFF",
  greyColor: "#4F5D70",
  blueColor: "#2c9aff",
  blueLightColor: "#84D0FF",
  greyLightColor: "#EDF1F4",
  tooltipShadow: "rgba(0, 0, 0, 0.25)",
  lineChartPrimary: "#071537",
  lineChartDanger: "#fd1c62",
  labelColor: "#6e6b7b",
  grid_line_color: "rgba(200, 200, 200, 0.2)",
}
const borderColor = {
  Windows: "#0a85e6",
  Linux: "#932b59",
  macos: "#9aa0a6",
}
export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardSubTitle,
    BCardTitle,
    ChartjsComponentLineChart,
  },
  props: {
    listAnalytics: {},
  },
  computed: {
    listGroupedByTimezone() {
      let listApi = this.listAnalytics.filter(
        x => x.IP_detail.time_zone !== undefined
      )
      return _.chain(listApi)
        .groupBy(x => _.first(x.IP_detail.time_zone.split("/")))
        .value()
    },
    lineChartData() {
      let arr = Object.values(this.listGroupedByTimezone)
      // console.log(arr)
      return arr.map(x =>
        x.map(y => {
          return y
          // console.log(y)
        })
      )
    },
    formatData() {
      const groupdata = {}
      this.listAnalytics.forEach(e => {
        if (groupdata[e.IP_detail.country_name] == undefined) {
          groupdata[e.IP_detail.country_name] = []
        }
        groupdata[e.IP_detail.country_name].push(e)
      })
      const country = Object.keys(groupdata)
      const y = {}
      const d = {}
      this.listAnalytics.forEach(e => {
        if (d[e.operating_system] == undefined) {
          d[e.operating_system] = {
            country: [],
            data: [],
            os: e.operating_system ? e.operating_system : 'Unknown OS',
          }
        }
        d[e.operating_system].data.push(e)
        d[e.operating_system].country.push(e.IP_detail.country_name)
      })
      const opData = Object.values(d)

      country.forEach(e => {
        opData.forEach(e1 => {
          if (y[e1.os] == undefined) {
            let osColor = "#fd1c62"
            if (borderColor[e1.os] !== undefined) {
              osColor = borderColor[e1.os]
            }
            y[e1.os] = {
              data: [],
              label: e1.os,
              borderColor: osColor,
              lineTension: 0.5,
              pointStyle: "circle",
              backgroundColor: osColor,
              fill: false,
              pointRadius: 1,
              pointHoverRadius: 5,
              pointHoverBorderWidth: 5,
              pointBorderColor: "transparent",
              pointHoverBorderColor: $themeColors.white,
              pointHoverBackgroundColor: osColor,
              pointShadowOffsetX: 1,
              pointShadowOffsetY: 1,
              pointShadowBlur: 5,
              pointShadowColor: chartColors.tooltipShadow,
            }
          }
          let totalUser = 0
          if (e1.country.includes(e)) {
            e1.data.forEach(e2 => {
              if (e2.IP_detail.country_name == e) {
                totalUser = totalUser + 1
              }
            })
          }
          y[e1.os].data.push(totalUser)
        })
      })
      return { labels: country, datasets: Object.values(y) }
    },
    lineChart() {
      return {
        options: {
          responsive: true,
          maintainAspectRatio: false,
          backgroundColor: false,
          hover: {
            mode: "label",
          },
          tooltips: {
            // Updated default tooltip UI
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            shadowColor: chartColors.tooltipShadow,
            backgroundColor: $themeColors.white,
            titleFontColor: $themeColors.black,
            bodyFontColor: $themeColors.black,
          },
          layout: {
            padding: {
              top: -15,
              bottom: -25,
              left: -15,
            },
          },
          scales: {
            xAxes: [
              {
                display: true,
                scaleLabel: {
                  display: true,
                },
                gridLines: {
                  display: true,
                  color: chartColors.grid_line_color,
                  zeroLineColor: chartColors.grid_line_color,
                },
                ticks: {
                  fontColor: chartColors.labelColor,
                },
              },
            ],
            yAxes: [
              {
                display: true,
                scaleLabel: {
                  display: true,
                },
                ticks: {
                  stepSize: 100,
                  min: 0,
                  max: 400,
                  fontColor: chartColors.labelColor,
                },
                gridLines: {
                  display: true,
                  color: chartColors.grid_line_color,
                  zeroLineColor: chartColors.grid_line_color,
                },
              },
            ],
          },
          legend: {
            position: "top",
            align: "start",
            labels: {
              usePointStyle: true,
              padding: 25,
              boxWidth: 9,
            },
          },
        },
        data: this.formatData,
      }
    },
  },
  data() {
    return {
      chartjsData,
      plugins: [
        // to add spacing between legends and chart
        {
          beforeInit(chart) {
            /* eslint-disable func-names, no-param-reassign */
            chart.legend.afterFit = function () {
              this.height += 20
            }
            /* eslint-enable */
          },
        },
      ],
    }
  },
}
</script>
