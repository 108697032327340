<template>
  <b-card title="Sessions by browser name" style="height: 94%">
    <div class="pie-text-2 text-center">
      <h2 class="font-weight-bolder">
        {{ countSessions }}
      </h2>
      <span class="font-weight-bold">Count sessions</span>
    </div>
    <!-- chart -->
    <chartjs-component-pie-chart
      v-if="doughnutChart.data && doughnutChart.data.datasets[0].data.length>0"
      :height="275"
      :data="doughnutChart.data"
      :options="doughnutChart.options"
      class="mb-3"
    />
    <!--/ chart --> 
    <!-- stocks -->
    <div
      v-for="(stock, index) in stockData"
      :key="stock.device"
      :class="index < stockData.length - 1 ? 'mb-1' : ''"
      class="d-flex justify-content-between"
    >
      <div class="d-flex align-items-center">
        <feather-icon :icon="stock.symbol" size="16" :class="stock.color" />
        <span class="font-weight-bold ml-75 mr-25">{{ stock.device }}</span>
        <span>- {{ stock.percentage }}%</span>
      </div>
      <!-- <div>
        <span>{{ stock.upDown }}%</span>
        <feather-icon
          :icon="stock.upDown > 0 ? 'ArrowUpIcon' : 'ArrowDownIcon'"
          :class="stock.upDown > 0 ? 'text-success' : 'text-danger'"
        />
      </div> -->
    </div>
    <!--/ stocks -->
  </b-card>
</template>

<script>
import { BCard } from "bootstrap-vue"
import ChartjsComponentPieChart from "./charts-components/ChartjsComponentPieChart.vue"
import chartjsData from "./chartjsData"
import { $themeColors } from "@themeConfig"

// colors
const chartColors = {
  primaryColorShade: "#836AF9",
  yellowColor: "#ffe800",
  successColorShade: "#28dac6",
  warningColorShade: "#ffe802",
  warningLightColor: "#FDAC34",
  infoColorShade: "#299AFF",
  greyColor: "#4F5D70",
  blueColor: "#2c9aff",
  blueLightColor: "#84D0FF",
  greyLightColor: "#EDF1F4",
  tooltipShadow: "rgba(0, 0, 0, 0.25)",
  lineChartPrimary: "#071537",
  lineChartDanger: "#fd1c62",
  labelColor: "#6e6b7b",
  grid_line_color: "rgba(200, 200, 200, 0.2)",
}

export default {
  components: {
    ChartjsComponentPieChart,
    BCard,
  },
  data() {
    return {
      chartjsData,
      stockData: [],
      doughnutChart: [{data:null,options:null}],
    }
  },
  props: {
    listAnalytics: {},
  },
  computed: {
    listGroupedByBrowserName() {
      let listApi = this.listAnalytics.filter(x => x.browser_name != null)
      return _.chain(listApi)
        .groupBy(x => x.browser_name)
        .value()
    },
    countSessions() {
      let countSessions = 0
      for (let browser in this.listGroupedByBrowserName) {
        countSessions += this.listGroupedByBrowserName[browser].length
      }
      return countSessions
    }
  },
  mounted() {
    this.initializeData()
  },
  methods: {
    initializeData() {
      this.stockData = Object.values(this.listGroupedByBrowserName).map(x => {
        return {
          device: x[0].browser_name && x[0].browser_name.length < 10 // Don't let too long browser names
          ? x[0].browser_name : 'Unknown browser',
          symbol: "MonitorIcon",
          percentage: Math.round(x.length * 100 / this.countSessions)
        }
      })

      this.doughnutChart = {
        options: {
          responsive: true,
          maintainAspectRatio: false,
          responsiveAnimationDuration: 500,
          cutoutPercentage: 60,
          legend: { display: false },
          tooltips: {
            callbacks: {
              label(tooltipItem, data) {
                const label = data.datasets[0].labels[tooltipItem.index] || ""
                const value = data.datasets[0].data[tooltipItem.index]
                const output = ` ${label} : ${value} %`
                return output
              },
            },
            // Updated default tooltip UI
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            shadowColor: chartColors.tooltipShadow,
            backgroundColor: $themeColors.light,
            titleFontColor: $themeColors.dark,
            bodyFontColor: $themeColors.dark,
          },
        },
        data: {
          datasets: [
            {
              labels: Object.values(this.listGroupedByBrowserName).map(
                x => x[0].browser_name && x[0].browser_name.length < 10 // Don't let too long browser names
                    ? x[0].browser_name : 'Unknown browser',
              ),
              data: Object.values(this.listGroupedByBrowserName).map(
                x => Math.round(x.length * 100 / this.countSessions)
              ),
              backgroundColor: [
                chartColors.lineChartPrimary,
                chartColors.lineChartDanger,
                chartColors.warningLightColor,
                chartColors.greyColor,
                chartColors.blueColor,
                chartColors.blueLightColor,
                chartColors.greyLightColor,
                chartColors.tooltipShadow,
              ],
              borderWidth: 0,
              pointStyle: "rectRounded",
            },
          ],
        },
      } 
    },
  },
}
</script>

<style lang="scss">
.card-body{
  position: relative;
  .pie-text-2{
    width: 105px;
    position:absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 170px;
    bottom: 0;
  }
  }
</style>
