<template>
  <b-card no-body>
    <b-card-header>
      <div>
        <b-card-title class="mb-1"> Statistics </b-card-title>
        <b-card-sub-title
          >Commercial number of user per country</b-card-sub-title
        >
      </div>
    </b-card-header>
    <b-card-body>
      <app-echart-bar :option-data="option" />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BCardSubTitle,
  BCardTitle,
} from "bootstrap-vue"
import AppEchartBar from "@core/components/charts/echart/AppEchartBar.vue"
import _ from "lodash"


export default {
  components: {
    BCard,
    AppEchartBar,
    BCardHeader,
    BCardBody,
    BCardSubTitle,
    BCardTitle,
  },
  props: {
    listAnalytics: {},
  },
  computed: {
    option() {
      const groupdata = {}
      this.listAnalytics.forEach(e => {
        if (groupdata[e.IP_detail.country_name] == undefined) {
          groupdata[e.IP_detail.country_name] = []
        }
        groupdata[e.IP_detail.country_name].push(e)
      })
      const country = Object.keys(groupdata)
      let data = Object.values(groupdata)
      const cityData = {}
      const chartData = []
      data?.forEach(e1 => {
      e1?.forEach(e => {
        if (cityData[e.IP_detail.city] == undefined) {
          cityData[e.IP_detail.city] = { user: 1, name: e.IP_detail.city }
        } else {
          cityData[e.IP_detail.city].user = cityData[e.IP_detail.city].user + 1
        }
      })
      })
      const u = Object.values(cityData)
      u.forEach(e => {
        chartData.push(e.user)
      }) 
      return {
        xAxis: [
          {
            type: "category",
            data: country,
          },
        ],
        yAxis: [
          {
            type: "value",
            splitLine: { show: false },
          },
        ],
        grid: {
          left: "40px",
          right: "30px",
          bottom: "30px",
        },
        series: [{data:chartData, type: 'bar'}],
      }
    },
    listGroupedByTimezone() {
      let listApi = this.listApi.filter(x => x.timezone !== undefined)
      return _.chain(listApi)
        .groupBy(x => x.timezone)
        .value()
    },
    listGroupedByCountry() {
      let listApi = this.listApi.filter(x => x.country_name !== undefined)
      return _.chain(listApi)
        .groupBy(x => x.country_name)
        .value()
    },
    formatData() {
      const groupdata = {}
      this.listAnalytics.forEach(e => {
        if (groupdata[e.IP_detail.country_name] == undefined) {
          groupdata[e.IP_detail.country_name] = []
        }
        groupdata[e.IP_detail.country_name].push(e)
      })
      const country = Object.keys(groupdata)
      let data = Object.values(groupdata)
      const cityData = {}
      data?.forEach(e => {
      e?.forEach(e1 => {
        if (cityData[e1.IP_detail.city] == undefined) {
          cityData[e1.IP_detail.city] = 1
        } else {
          cityData[e1.IP_detail.city] = cityData[e1.IP_detail.city] + 1
        }
      })
      })
      // console.log({ xAxisData: country, series: data });
      return { xAxisData: country, series: data }
    },
  },
}
</script>
