<template>
  <b-card-code title="Marker, Circle & Polygon Map">
    <l-map
        :zoom="zoom"
        :center="center"
        :max-bounds="maxBounds"
        minZoom=2
    >
      <l-tile-layer :url="url"/>
      <l-marker
          v-for="marker in markers"
          :key="marker.id"
          :visible="marker.visible"
          :draggable="marker.draggable"
          :lat-lng.sync="marker.position"
          :icon="marker.icon"
          @click="alert(marker)"
      >
        <l-popup :content="marker.tooltip"/>
        <l-tooltip :content="marker.tooltip"/>
      </l-marker>
    </l-map>
  </b-card-code>
</template>

<script>
/* eslint-disable global-require */
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  LMap, LTileLayer, LMarker, 
} from 'vue2-leaflet'
import {Icon} from 'leaflet'
import 'leaflet/dist/leaflet.css'

// eslint-disable-next-line no-underscore-dangle
delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})
/* eslint-disable global-require */

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    BCardCode, 
  },
  props: {
    listAnalytics: {}
  },
  data() {
    return {
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      zoom: 2,
      center: [47.313220, -1.319482],
      markerLatLng: [47.313220, -1.319482],
      bounds: null,
      maxBounds: ([
        [93.52839948765174, -168.71093750000003],
        [-85.779905342529634, 228.51562500000003]
      ]),
      maxZoom: 2,
    }
  },
  computed: {
    markers() {
      let listObjects =  Object.values(this.listAnalytics).map(x => {
        if (x.IP_detail.latitude && x.IP_detail.longitude) {
          return {
            id: x.id,
            position: {lat: x.IP_detail.latitude, lng: x.IP_detail.longitude},
            tooltip: `${x.IP_detail.country_name}, ${x.IP_detail.city}, ${x.IP_detail.postal_code}`,
            draggable: true,
            visible: true,
          }
        } else {
          return null
        }
        
      })

      let filteredListObjects = {};
      for (let key in listObjects) {
        if (listObjects[key]) {
          filteredListObjects[key] = listObjects[key]
        }
      }

      return(filteredListObjects)
    }
  },
}
</script>

<style lang="scss">
.vue2leaflet-map {
  &.leaflet-container {
    height: 350px;
  }
}
</style>
